<template>
	<div class="out" ref="out"  >
     <!-- <van-pull-refresh v-model="isLoading" @refresh="loadDoodState"> -->
       <div class="header">
			<div class="logo Title">
				<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
				@click="$router.back()" /> {{$t('main.myc')}}</div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{$t('main.title')}}
				</div>
			</div>
		</div>
		<div class="tarbar">
			<div class="item" :class="is_cul?'':'active'" @click="changeCul(false)">珍宝</div>
			<div class="item" :class="is_cul?'active':''" @click="changeCul(true)" >文创品</div>
		</div>
			
			<div class="body" v-if="is_cul?cul_list.length:list.length">
				<div class="wrap">
					<div class="goods"  ref="imgBox" v-for="item in showList" :key="item.goods_id" >
						<div class="item-image" @click="goDetail(item)">
							<img class="goods-image" v-lazy="item.pictures[0] + '?width=200'" :style="`height:${(item._height / 100)}rem`" alt="" />
							<!-- <img class="show-shou" v-if="item.is_can_buy == 1" src="../assets/img/is-show-shou.png" alt=""> -->
						</div>
						<div class="goods-wrap">
							<div class="goods-title">{{is_cul?item.goods_derivative_name:item.goods_name}}</div>
							<div class="price-wrap" v-show="!is_cul">
								<div class="price-title">{{$t('main.value')}}</div>
								<div class="price">￥{{thousands(item.price)}}</div>
							</div>
							<div class="logo-wrap" v-show="!is_cul">
								<div  class="goods-logo">
									<img v-if="item.artist_type == 1" src="../assets/img/tc-author.png" alt="" />
									<img v-if="item.artist_type == 2 && item.is_collector_inst == 0" src="../assets/img/tc-Collector.png" alt="" />
									<img v-if="item.artist_type == 2 && item.is_collector_inst == 1" style="width:0.65rem;height:0.18rem;" src="../assets/img/scjg.png" />
								</div>
								<div class="goods-name">{{item.artist_name}}</div>
							</div>
						</div>
					</div>
				</div> 
				
			</div>
			<div class="body" v-if="is_cul?!cul_list.length:!list.length">
				<div  class="empty_wrap">
					<div class="empty">
						<img src="../assets/img/no-data-tishi.png" alt="" />
						{{is_cul?'暂无收藏，快去收藏文创品吧！':$t('main.nctoT')}}
					</div>
				</div>
			</div>
		
		
	  <!-- </van-pull-refresh> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				isLoading:false,
				imgWidth:168,
				loadedCount:0,
				showList:[],
				beginIndex:0,
				colsHeightArr:[],
				colNum: 2,
				cul_list:[],
				is_cul:false
			};
		},
		mounted() {
			this.protocol = location.protocol
			this.axios.get("/goods/getSaveList").then((res) => {
				if(!res.data.data.list){
					return
				}
				if(this.$i18n.locale == 'en'){
					for(let i = 0; i < res.data.data.list.length;i++){
						res.data.data.list[i].artist_name = res.data.data.list[i].artist_name_intl ? res.data.data.list[i].artist_name_intl : res.data.data.list[i].artist_name
						res.data.data.list[i].goods_name = res.data.data.list[i].goods_name_intl ? res.data.data.list[i].goods_name_intl : res.data.data.list[i].goods_name
					}
				}
				
				this.list = res.data.data.list;
				if(res.data.data.list.length){
					this.preLoad()
				}
			});
			this.axios.get("goodsder/getSaveList").then((res) => {
				if(!res.data.data.list){
					return
				}
				if(this.$i18n.locale == 'en'){
					for(let i = 0; i < res.data.data.list.length;i++){
						res.data.data.list[i].artist_name = res.data.data.list[i].artist_name_intl ? res.data.data.list[i].artist_name_intl : res.data.data.list[i].artist_name
						res.data.data.list[i].goods_name = res.data.data.list[i].goods_name_intl ? res.data.data.list[i].goods_name_intl : res.data.data.list[i].goods_name
					}
				}
				
				this.cul_list = res.data.data.list;
				// if(res.data.data.list.length){
				// 	this.preLoad1()
				// }
			});
		},
		methods: {
			// loadDoodState() {
			// 	this.isLoading = true;
			// 	this.axios.get("/goods/getSaveList").then((res) => {
				
			// 		this.list = res.data.data.list;
			// 		this.preLoad()
			// 		this.isLoading = false;
			// 	});
			// },
			changeCul(bool){
				this.is_cul = bool
				this.loadedCount = 0
				this.showList = []
				if(this.is_cul?this.cul_list.length > 0 :this.list.length > 0)
				this.preLoad()
			},
			preLoad() {
				this.loading = weui.loading(this.$t('main.loading'))
				let list = this.is_cul ? this.cul_list : this.list
				list.forEach((item, index) => {
					console.log(111)
					if (!item.pictures) {
						
						list[index]._height = "0";
						++this.loadedCount;
						
						if (list.length === this.loadedCount) {
							this.preloaded();
						}
					} else {
						let img = new Image();

						img.onload = img.onerror = (e) => {

							
							list[index]._height =
								e.type === "load" ?
								Math.round(this.imgWidth * (img.height / img.width)) :
								this.imgWidth;
							if (e.type === "error") {
								list[index]._error = true;
							}
							++this.loadedCount;
							
							if (list.length === this.loadedCount) {
								this.preloaded();
							}
						};
						img.src = (item.pictures[0] + '?width=165').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol);
					}
				});
			},
			preloaded() {
				if(this.is_cul){
					this.showList = this.showList.concat(this.cul_list);
				}else{
					this.showList = this.showList.concat(this.list);
				}
				
				this.$nextTick(() => {
					this.waterfall();
				});
			},
			thousands(num){
				var splits=[],res=[];
				var splits = num.toString().split(".");
				splits[0].split("").reverse().map(function(item,i){
					if(i%3 == 0 && i!=0){ res.push(","); }
					res.push(item);
				});
				return res.reverse().join("")+(splits.length>1 ? "."+splits[1] : "");
			},
			goDetail(item){
				if(this.is_cul){
					this.$router.push(`/detail_cul/${item.goods_derivative_id}?status=1`)
				}else{
					this.$router.push(`/detail/${item.goods_id}?status=1`);
				}
				
			},
			waterfall() {
				this.loading.hide()
				this.imgBoxEls = this.$refs["imgBox"];
				if (!this.imgBoxEls) return;
				let top, left, height;
				if (this.beginIndex === 0) this.colsHeightArr = [];
				for (let i = this.beginIndex; i < this.imgBoxEls.length; ++i) {
					if (!this.imgBoxEls[i]) return;
					// 当前图片的高度

					height = this.imgBoxEls[i].offsetHeight + 15
					// 第一行，则直接按顺序排列
					if (i < this.colNum) {
						this.colsHeightArr.push(height);
						top = 0;
						// colWidth 为列宽，等于图片宽度加 div 左右的padding，colWidth = imgWdith + 2 * padding
						left = i * 1.8 + 0.15;
					} else {
						// 找到当前最低的高度和其索引
						let minHeight = Math.min.apply(null, this.colsHeightArr);
						let minIdx = this.colsHeightArr.indexOf(minHeight);
						// 当前图片的 top，即当前图片应所在的高度
						top = minHeight;
						// 当前图片的 left，即当前图片应该排到目前高度最低那一列下面
						left = minIdx * 1.8 + 0.15;
						// 更新第 minIdx 列的高度
						this.colsHeightArr[minIdx] += height;
					}
					// 设置 img-box 位置
					this.imgBoxEls[i].style.top = (top + 15) + "px";
					this.imgBoxEls[i].style.left = left + "rem";
					
					// 当前图片在窗口内，则加载，这是用于后面的图片懒加载。viewHeight 为窗口高度
					if (top < this.viewHeight) {
						let imgEl = this.imgBoxEls[i].children[0];
						imgEl.src = imgEl.getAttribute("data-src");
						imgEl.style.opacity = 1;
						imgEl.style.transform = "scale(1)";
					}
				}
				// let maxHeight = Math.max.apply(null, this.colsHeightArr);
				
				// this.$refs.bottom.style.top = (maxHeight+15)+"px"
			},
        }
	};
</script>

<style scoped>
.out {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  background-attachment: fixed;
		/* overflow: auto; */
  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: 100% 100%;
}
.body {
  min-height: calc(100vh - 0.55rem);
  /* padding: 0.15rem; */
  
  overflow-y: auto;
  position: relative;
  /* background-attachment: fixed; */
}
.header {
	padding: 0.05rem 0.15rem 0.09rem;
	display: flex;
	justify-content: space-between;
  box-sizing: border-box;
	/* line-height: 0; */
	align-items: center;
	background: #FFFFFF;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}



.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}
.empty {
  border-radius: 0.1rem;
  margin: 0.4rem auto;
  width: 3.205rem;
  color: #b28d80;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.16rem;
  text-align: center;
  padding-bottom: 0.29rem;
}
.empty img {
  margin-top: 0.84rem;
  margin-bottom: 0.30rem;
  width: 1.975rem;
  object-fit: contain;
  display: block;
}
.wrap{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: flex-start;
	/* position: relative; */
	
	/* overflow: visible */
}
.goods{
	background: #fff;
	width: 1.65rem;
	margin-bottom: 0.1rem;
	font-size: 0;
	position:absolute; 
}

.goods .goods-image{
	width: 100%;
}
.goods .item-image{
	position: relative;
}
.goods .item-image .show-shou{
	width: 0.22rem;
	height: 0.22rem;
	position: absolute;
	right: 0;
	margin: 0.02rem;
}
.goods .goods-wrap{
	padding: 0.1rem;
}
.goods .goods-title{
	font-size: 0.15rem;
}
.goods .price-wrap{
	border-top: 0.01rem solid #ccc;
	margin-top: 0.05rem;
	padding-top: 0.05rem;
}
.goods .price-wrap{
	display: flex;
	justify-content: space-between;
}
.goods .price-title{
	font-size: 0.12rem;
	color: #878787;
}
.goods .price{
	font-size: 0.12rem;
	color: #F44040;
}
.goods .logo-wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.08rem;
}
.goods .goods-logo{
    text-align: center;
	line-height: 0.20rem;
}
.goods .goods-logo img{
	vertical-align: middle;
	height: 0.18rem;
}
.goods .goods-name{
	font-size: 0.12rem;
	color: #878787;
	width: auto;
    overflow: hidden;
	text-align: right;
}
.tarbar{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.1rem;
  color: #929292;
  font-size: 0.18rem;
}
.tarbar .item{
  padding-bottom: 0.08rem;
}
.tarbar .item.active{
  color: #a72d28;
  border-bottom: solid 1px #a72d28;
}
</style>
